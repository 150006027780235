<template>
  <v-data-table
    :headers="headers"
    :items="regions"
    sort-by="name"
    class="elevation-1"
    :search="search"
    :loading="loadingTable"
    :loading-text="loadingText"
  >
    <template v-slot:top>
      <div class="tw-px-2 md:tw-px-6 tw-mb-4">
        <card-title icon="mdi-web" :is-image="false">Regions</card-title>
      </div>
      <region-form
        @updateDialog="updateDialog"
        :region="editedRegion"
        :is-edit="true"
        :open-dialog="dialog"
      />
    </template>
    <template v-slot:[`item.id`]="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" color="secondary" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small color="error" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import RegionForm from '@/components/region-management/RegionForm'
import axios from 'axios'
export default {
  components: { CardTitle, RegionForm },
  props: {
    regions: Array,
    loadingTable: Boolean,
  },
  data: () => ({
    dialog: false,
    search: '',
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Region Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      { text: 'Actions', align: 'end', value: 'actions', sortable: false },
    ],
    editedRegion: null,
    loadingText: '',
  }),

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  methods: {
    editItem(item) {
      this.editedRegion = item
      this.dialog = true
    },

    deleteItem(item) {
      confirm('Are you sure you want to delete this item?')
      axios
        .delete(`/motor-assessment/api/regions/${item.name}`)
        .then(() => {
          this.$toasted.success(`${item.name} deleted successfully`, {
            duration: 3000,
          })
          this.$emit('refreshRegions')
        })
        .catch(() => {
          this.$toasted.error('An error occurred, try again', {
            duration: 3000,
          })
        })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedRegion = null
      })
    },

    updateDialog(newValue) {
      this.dialog = newValue
    },
  },
}
</script>
