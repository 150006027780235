<template>
  <div>
    <v-btn
      v-if="!isEdit"
      bottom
      class="secondary"
      dark
      fab
      fixed
      right
      id="createUserModalBtn"
      @click="dialog = !dialog"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card class="mx-auto">
        <v-form ref="form" v-model="valid">
          <v-container>
            <div class="tw-px-2 md:tw-px-6 tw-mb-3">
              <v-card-title icon="mdi-web" :is-image="false">{{
                formTitle
              }}</v-card-title>
            </div>
            <v-card-text class="text-center">
              <p class="px-10 mb-0">
                {{ formDescription }}
              </p>
            </v-card-text>

            <v-card-actions class="mx-auto px-12">
              <v-row class="mt-3">
                <v-col cols="12">
                  <v-text-field
                    id="regionName"
                    type="text"
                    label="Region Name"
                    v-model="name"
                    required
                    placeholder="Nairobi"
                    :rules="[required('Name')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    color="primary"
                    class="my-5"
                    large
                    block
                    @click="cancel"
                  >
                    Cancel</v-btn
                  >
                </v-col>
                <v-col class="my-5" cols="6">
                  <v-btn
                    color="secondary"
                    class="mb-5"
                    large
                    block
                    @click="submitForm"
                    :loading="loading"
                    :disabled="!valid"
                  >
                    {{ isEdit ? 'Update' : 'Onboard' }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import validation from '@/utils/Validations'
import axios from 'axios'
export default {
  props: {
    isEdit: {
      default: false,
      type: Boolean,
    },
    openDialog: {
      default: false,
      type: Boolean,
    },
    region: {
      default: null,
      type: Object,
    },
  },
  data: () => ({
    valid: false,
    dialog: false,
    loading: false,
    name: null,
    description: null,
    ...validation,
  }),

  computed: {
    formTitle: function () {
      return this.isEdit ? 'Edit Region' : 'Create Region'
    },
    formDescription: function () {
      return !this.isEdit
        ? 'Please enter the name of the region you wish to add in the list of assessment centers regions'
        : 'Update the name of the region'
    },
    toastMessage: function () {
      return this.isEdit
        ? 'Region updated successfully'
        : 'Region added successfully'
    },
  },

  watch: {
    openDialog: function (newValue) {
      this.name = this.region.name ?? null
      this.dialog = newValue
    },
    dialog: function (newValue) {
      if (this.isEdit && this.openDialog) {
        this.$emit('updateDialog', newValue)
      }
    },
    name: function (newValue) {
      if (newValue) this.name = newValue.toUpperCase()
    },
  },

  methods: {
    submitForm() {
      this.loading = true
      if (this.isEdit) {
        this.editRegion()
        return
      }
      axios
        .post('/motor-assessment/api/regions', {
          name: this.name,
          description: this.name,
        })
        .then((res) => {
          if (res.data.success) {
            this.$toasted.success(res.data.msg, {
              duration: 3000,
            })
          } else {
            this.$toasted.error(res.data.msg, {
              duration: 3000,
            })
          }
          this.$refs.form.reset()
          this.dialog = false
          this.$emit('refreshRegions')
        })
        .catch(() => {
          this.showErrorMessage()
        })
      this.loading = false
    },

    editRegion: function () {
      axios
        .patch(`/motor-assessment/api/regions/${this.region.name}`, {
          name: this.name,
          description: this.name,
        })
        .then(() => {
          this.$toasted.success(this.toastMessage, {
            duration: 3000,
          })
          this.$refs.form.reset()
          this.dialog = false
          this.$emit('refreshRegions')
        })
        .catch(() => {
          this.showErrorMessage()
        })
      this.loading = false
    },

    showErrorMessage: function () {
      this.$toasted.error('An error occurred, try again', {
        duration: 3000,
      })
    },

    cancel() {
      this.dialog = false
      this.loading = false
      this.$refs.form.reset()
    },
  },
}
</script>
