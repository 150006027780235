<template>
  <div class="accent">
    <v-container fluid>
      <v-row class="mx-auto py-4">
        <v-col cols="12">
          <h1>
            <router-link :to="{ name: 'RegionManagement' }"
              >Region management</router-link
            >
            | <span class="title"> Regions</span>
          </h1>
        </v-col>
      </v-row>
      <v-container>
        <v-row class="mt-10">
          <v-col class="mx-auto" cols="12" md="12">
            <regions-table
              @refreshRegions="fetchRegions"
              :loading-table="loadingTable"
              :regions="regions"
            />
          </v-col>
        </v-row>
      </v-container>
      <region-form @refreshRegions="fetchRegions" />
    </v-container>
  </div>
</template>

<script>
import RegionsTable from '@/components/region-management/RegionsTable'
import RegionForm from '@/components/region-management/RegionForm'
import axios from 'axios'
export default {
  name: 'RegionManagement',
  components: {
    RegionForm,
    RegionsTable,
  },
  props: {
    source: String,
  },
  data: () => ({
    dialog: false,
    drawer: null,
    snackback: false,
    loadingTable: true,
    regions: [],
  }),
  mounted() {
    this.fetchRegions()
  },
  methods: {
    fetchRegions() {
      this.loadingTable = true
      this.loadingText = 'Loading... Please wait'
      axios
        .get('/motor-assessment/api/regions')
        .then((res) => {
          this.regions = res.data.data
          this.loadingText = ''
        })
        .catch((err) => {
          this.loadingText = err.response.data.message
          this.$store.commit(
            'SNACKBAR',
            {
              state: 'failed',
              message: `${err.response.data.msg}`,
              variant: 'error',
            },
            { root: true }
          )
        })
      this.loadingTable = false
    },
  },
}
</script>
